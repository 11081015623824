<template>
  <div>
    <div class="domesticDetails">
      <div class="line"></div>
      <div class="navigation">
        <span @click="skip()"
              v-show="aboutname!==undefined">{{aboutname}}</span>
        <h3 v-if="aboutname != columnName && columnName != undefined && columnName != '' ">
          |
        </h3>
        <p @click="skip1()"
           v-if="aboutname != columnName">
          {{columnName}}
        </p>
        <h4>
          <i @click="skip3()">首页</i>
          <i v-if="aboutname != columnName && aboutname != undefined && aboutname != ''"
             @click="skip()">{{aboutname}}</i>
          <i @click="skip1()"
             v-if="columnName != undefined && columnName != ''">{{columnName}}</i>
          <i>正文</i>
        </h4>
      </div>
      <div class="content">
        <div class="contentLeft">
          <div class="title">{{dataContent.title}}</div>
          <div class="time flex"><span>{{dataContent.releaseTime | escapeHTML}}</span>
            <p>来源：{{textSource}}</p>
            <h2></h2>
          </div>
          <div class="textContent _menhu-article">
            <div v-html="dataContent.content"></div>
            <h3 class="art-socure">本文来源：<span>{{textSource}}</span>责任编辑：{{dataContent.dutyEditor}}</h3>
          </div>
        </div>
        <div class="contentRight">
          <div class="rightImg"
               v-if="bannerImg.length"><img :src="bannerImg[0].picturePath"
                 alt=""></div>
          <div class="head flex">
            <div class="head-left">
              专题推荐
            </div>
            <div class="head-right">
            </div>
          </div>
          <ul>
            <li v-for="item in recommendList"
                :key="item.id"
                @click="skip2(item)">{{item.title}}</li>
          </ul>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import { getNews, getDetail, getdetail, getWebTitle } from '../../api/home1'
import { getdynamicList } from '@/api/home'
import Foot from '../components/footer'
import { getBanner } from '@/api/banner'
 import {constCommon} from '@/consts/common'
export default {
  components: {
    Foot
  },
  data() {
    return {
      constCommon:constCommon,
      webTitle: '',
      aboutname: '',
      columnName: '',
      paths: '',
      paths1: '',
      recommendList: '',
      columnNameId: '',
      icon: '',
      titleId: '',
      dataContent: '',
      bannerImg: '',
      columid: '',
      textSource: ''
    }
  },
  beforeRouteLeave(to, form, next) {
    this.getWebTitle2()
    next()
  },
  methods: {
    getbanner() {
      getBanner({
        columnId: '1366599590035247105',
        type: 2
      }).then((res) => {
        this.bannerImg = res.data
      })
    },
    skip() {
      if (this.aboutname == '猪价行情') {
        this.$router.push({
          path: '/pigprico',
          query: {
            columid: this.columid,
            // columnNameId: this.columid
          }
        })
        return
      }
      if (this.path == '/about') {
        this.$router.push({
          path: this.path,
          query: {
            columid: this.columid
            // columnNameId: this.columnNameId
          }
        })
      }
      if (this.columnName == undefined) {
        this.skip1()
        return
      }
      if (this.aboutname == constCommon.shortName || this.columid == '1393021449385963522') {
        this.$router.push({
          path: '/agricuunforThree',
          query: {
            columnNameId: this.columid
            // columnNameId: this.columnNameId
          }
        })
        return
      }
      // if (this.aboutname !== '农业政策') {
      //   this.$router.push({
      //     path: this.paths,
      //     query: {
      //       columid: this.columid,
      //       columnNameId: this.columnNameId
      //     }
      //   })
      // }
      if (this.aboutname == '惠农政策') {
        this.$router.push({
          path: '/BenefitfarmersPolicy',
          query: {
            columid: this.columid
          }
        })
      }
    },
    skip3() {
      this.$router.push('/home')
    },
    skip2(item) {
      this.titleId = item.id
      this.getLeftContent()
      window.scrollTo(0, 0)
      getdetail({
        id: item.columnId
      }).then((res) => {
        this.columnName = res.data.columnName
        this.columnNameId = res.data.id
        this.columid = res.data.parentId
        this.aboutname = res.data.parentColumnName
        this.paths = res.data.parentAccessPath
      })
    },
    skip1() {
      if (this.aboutname == '猪价行情') {
        this.$router.push({
          path: '/pigprico',
          query: {
            // aboutname: this.aboutname,
            columid: this.columid,
            paths: this.paths,
            columnName: this.aboutname,
            columnNameId: this.columid
          }
        })
        return
      }
      if (this.aboutname == '农业政策') {
        this.$router.push('/BenefitfarmersPolicy')
        return
      }
      if(this.columnName == '猪业资讯'){
        this.$router.push({
          path: '/piginfothree',
          query: {
            // columid: this.columid,
            columnNameId: this.columnNameId
          }
        })
         return
      }
      this.$router.push({
        path: '/agricuunforThree',
        query: {
          // columid: this.columid,
          columnNameId: this.columnNameId
        }
      })
    },
    //获取网站名称
    getWebTitle2() {
      getWebTitle().then((res) => {
        if (!res.data.records[0].status) {
          document.title = res.data.records[0].websiteName
        }else{
          document.title = ''
        }

      })
    },
    getLeftContent() {
      getDetail({ id: this.titleId }).then((res) => {
        this.dataContent = res.data
        this.columnNameId = res.data.columnId
        document.title = this.dataContent.title
        if (this.dataContent.source == 1) {
          this.textSource = '本网站'
          return
        }
        if (this.dataContent.source == 3) {
          this.textSource = this.dataContent.websetName
          return
        }
        if (
          this.dataContent.source == 2 &&
          this.dataContent.sourceValue == ''
        ) {
          this.textSource = this.dataContent.websetName
        } else {
          this.textSource = this.dataContent.sourceValue
        }

      })
      setTimeout(() => {
        getdetail({ id: this.columnNameId }).then((res) => {
          if (res.data.parentColumnName == '') {
            this.aboutname = res.data.columnName
            this.path = res.data.accessPath
            this.columid = res.data.id
          } else {
            this.aboutname = res.data.parentColumnName
            this.columnName = res.data.columnName
            this.columid = res.data.parentId
            this.path = res.data.parentAccessPath
          }
        })
      }, 700)
    },

    getNewsList() {
      getNews({ type: 3 }).then((res) => {
        this.recommendList = res.data.records
      })
    }
  },
  mounted() {
    // this.columnNameId = this.$route.query.columnNameId //二级栏目id/
    this.titleId = this.$route.query.titleId //详情title的id
    // this.columid = this.$route.query.columid //一级栏目的id
    this.getLeftContent()
    this.getNewsList()
    this.getbanner()
  },
  filters: {
    escapeHTML(untrusted) {
      if (untrusted != undefined) {
        return untrusted.substring(0, 16)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.domesticDetails {
  margin: 0 auto;
  width: 1300px;
  .line {
    position: absolute;
    border-top: 2px solid #51d5c3;
    height: 2px;
    width: 1400px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 184px;
    margin: 0 auto;
  }
  .navigation {
    height: 40px;
    padding-top: 20px;
    display: flex;
    span {
      color: #51d5c3;
      font-size: 20px;
      font-weight: bolder;
      margin-right: 10px;
      cursor: pointer;
      line-height: 20px;
    }
    p {
      font-size: 20px;
      font-weight: bolder;
      margin: 0 20px 0 10px;
      cursor: pointer;
      line-height: 20px;
    }
    h4 {
      margin-top: 2px;
      color: #797979;
      i {
        cursor: pointer;
      }
      i + i::before {
        content: '>';
        margin: 0 5px;
        color: #797979;
      }
      i:hover {
        color: #51d5c3;
      }
      i:last-child:hover {
        color: #797979;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .contentLeft {
      width: 66%;
      .title {
        font-size: 24px;
        font-weight: bolder;
        line-height: 50px;
      }
      .time {
        padding-bottom: 15px;
        border-bottom: 1px solid #dadada;
        margin-bottom: 30px;
        margin-top: 15px;
        color: #898989;
        justify-content: start;
        span {
          margin-right: 5px;
        }
        p {
          margin-right: 25px;
        }
        h2 {
          font-size: 14px;
        }
      }
      .textContent {
        line-height: 28px;
        color: #444;
        font-size: 15px;
        h3 {
          float: right;
          margin-top: 20px;
          font-size: 14px;
          span {
            margin-right: 10px;
          }
        }
      }
      .textContent:first-child {
        text-indent: 35px;
      }
    }
    .contentRight {
      .rightImg {
        width: 400px;
        margin-bottom: 25px;
        margin-top: 20px;
      }
      .head {
        .head-left {
          color: #fff;
          font-size: 18px;
          text-align: center;
          padding: 0 20px;
          background: #51d5c3;
          line-height: 40px;
          white-space: nowrap;
          text-align: center;
        }
        .head-right {
          border-bottom: 1px solid #dddddd;
          width: 100%;
        }
      }
      ul {
        li {
          width: 400px;
          border-bottom: 1px solid #d9c8b2;
          line-height: 45px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          color: #757575;
        }
        li:hover {
          color: #51d5c3;
        }
      }
    }
  }
}
.art-socure {
  color: #a0a0a0;
  margin-top: 50px !important;
}
.art-socure:before {
  width: 16px;
  height: 16px;
  content: '';
  display: inline-block;
  background: #45dfca;
  vertical-align: middle;
  line-height: 0;
  margin-right: 10px;
  border-radius: 4px;
}
</style>
<style>
._menhu-article p {
  text-indent: 2em;
  margin-bottom: 10px;
}
</style>
